import { createApp } from 'vue'
import { createStore } from 'vuex';
import App from './App.vue'

//import router from './router'
//router/index.js start:
import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/Home.vue'
import Profile from '@/views/Profile.vue'
import Keys from '@/views/Keys.vue'
import Keys1 from '@/views/Keys1.vue'

// import store from './store'

// Define the store
const store = createStore({
  state: {
    signupEmail: '',
  },
  mutations: {
    setSignupEmail(state, email) {
      state.signupEmail = email;
    },
  },
});

const routes = [

  {
    path: '/',
    name: 'home',
    component: Home
  },

  { path: '/keys', name: 'keys', component: Keys },
  {
    path: '/keys/:userId', 
    component: Keys1,
  },

  { path: '/holders', name: 'holders', component: () => import('@/views/Holders.vue') },
  { path: '/mint', name: 'mint', component: () => import('@/views/Mint.vue') },

  { path: '/signup', name: 'signup', component: () => import('@/views/SignUp.vue') },
  { path: '/signup1', name: 'signup1', component: () => import('@/views/SignUp1.vue'), props: true }, // /:email
  // { path: '/signup2', name: 'signup2', component: () => import('@/views/SignUp2.vue') },
  // { path: '/signup3', name: 'signup3', component: () => import('@/views/SignUp3.vue') },
  { path: '/signin', name: 'signin', component: () => import('@/views/SignIn.vue') },
  { path: '/signin1', name: 'signin1', component: () => import('@/views/SignIn1.vue'), props: true },
  { path: '/signin2', name: 'signin2', component: () => import('@/views/SignIn2.vue') },
  { path: '/signlost', name: 'signlost', component: () => import('@/views/SignLost.vue') },
  { path: '/signlost2fa', name: 'signlost2fa', component: () => import('@/views/SignLost2FA.vue') },

  {
    path: '/profile',
    name: 'profile',
    component: Profile,
      children: [
        { path: '', name: 'Details', component: () => import('@/views/ProfileDetails.vue') },
        { path: 'inbox', name: 'Inbox', component: () => import('@/views/ProfileInbox.vue') },
        { path: 'tokens', name: 'Tokens', component: () => import('@/views/ProfileTokens.vue') },
        { path: 'tokensno', name: 'TokensNo', component: () => import('@/views/ProfileTokensNo.vue') },
        { path: 'token', name: 'Token', component: () => import('@/views/ProfileToken.vue') },
        { path: 'tokenbuy', name: 'TokenBuy', component: () => import('@/views/ProfileTokenBuy.vue') },
        { path: 'myhold', name: 'MyHold', component: () => import('@/views/ProfileHolds.vue') },
        { path: 'profile', name: 'ProfileData', component: () => import('@/views/ProfileData.vue') },
        { path: 'settings', name: 'Settings', component: () => import('@/views/ProfileSettings.vue') },
      ]
  },

  {
    path: '/:catchAll(.*)*',
    name: "Error",
    component: Error,
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// router/index.js end

createApp(App)
  .use(store)
  .use(router)
  .mount('#app')
