<template>
    <div
      class="md:h-18 w-full 
      bg-[#FFF2D1] border-b border-b-[#C7C1B2] 
      flex flex-col md:flex-row px-10 py-4 max-md:max-w-full max-md:px-5"
    >
      <div
        class="w-full flex flex-row
        max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:pr-2 "
      >
        <router-link to="/"><img
          loading="lazy"
          src="/static/mintage.png"
          class="w-[160px] my-auto max-md:w-[140px]"
        /></router-link>

        <div
          class="w-[65px] my-auto ml-auto"
        >

          <div class="relative" v-on:mouseleave="showModal = false">
            <router-link class="text-xl whitespace-nowrap my-auto border border-gray-700 rounded-lg px-4 py-2" to="/signin" v-on:mouseover="showModal = true">EN</router-link>
            <div class="absolute right-0 top-full mt-2 w-48 rounded-xl shadow-xl bg-white z-50" v-show="showModal">
              <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <div class="px-4 py-2" v-for="(language, index) in languages" :key="index">
                  <!--<img :src="language.flag" :alt="language.name" class="inline-block mr-2">-->
                  <span>{{ language.name }}</span>
                </div>
              </div>
            </div>

        </div>
      </div>
    </div>

  </div>
  </template>

<script>
export default {
  data() {
    return {
      showModal: false,
      languages: [
        { name: 'English', flag: 'path/to/english-flag.png' },
        { name: 'Spanish', flag: 'path/to/spanish-flag.png' },
        { name: 'French', flag: 'path/to/french-flag.png' },
        { name: 'German', flag: 'path/to/german-flag.png' },
        { name: 'Chinese', flag: 'path/to/chinese-flag.png' },
      ],
    };
  },
};
</script>