<template>
  <div
    class="md:h-18 justify-center items-center self-stretch 
    bg-[#FFF2D1] border-b border-b-[#C7C1B2] flex w-full 
    flex-col px-10 py-4 max-md:max-w-full max-md:px-5"
  >
    <div
      class="justify-between items-start self-center flex w-full gap-5 max-md:gap-2 pr-6 
      max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:pr-2"
    >
      <router-link to="/"><img
        loading="lazy"
        src="/static/mintage.png"
        class="w-[160px] max-md:w-[140px]"
      /></router-link>
      <div
        class="items-start flex w-[560px] max-w-full pl-4 
        gap-5 mr-auto max-md:flex-wrap max-md:justify-center
        "
      >
                            
                    <!-- <router-link class="tab" 
                    :to="{ name: 'Details' }"
                    :class="{ 'act': isActiveTab('Details') }"
                    >Wallet</router-link> -->
                    <!--   <router-link class="tab" 
                    :to="{ name: 'Inbox' }"
                    :class="{ 'act': isActiveTab('Inbox') }"
                    >Inbox <img src="/static/mail.svg" class="inline ml-1" width="18"></router-link> -->

                    <router-link class="tab" :to="{ name: 'TokensNo' }" :class="{ 'act': isActiveTab('TokensNo') }">Tokens</router-link>
                    <router-link class="tab" :to="{ name: 'MyHold' }" :class="{ 'act': isActiveTab('MyHold') }">Transactions</router-link>
                    

        
      </div>
      <!-- Right Items -->
      <div
        class="items-start self-stretch flex max-w-full my-auto gap-5"
      >

        <router-link class="tab" :to="{ name: 'Settings' }" :class="{ 'act': isActiveTab('Settings') }">Profile</router-link>
        <div class="relative my-auto" v-on:mouseleave="showModal = false">

          <router-link class="text-xl whitespace-nowrap my-auto border border-gray-700 rounded-lg px-4 py-2" to="/signin" v-on:mouseover="showModal = true">EN</router-link>
          <div class="absolute right-0 top-full mt-2 w-48 rounded-xl shadow-xl bg-white z-50" v-show="showModal">
            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <div class="px-4 py-2" v-for="(language, index) in languages" :key="index">
                <!--<img :src="language.flag" :alt="language.name" class="inline-block mr-2">-->
                <span>{{ language.name }}</span>
              </div>
            </div>
          </div>

        </div>
        <router-link class="my-auto" to="/signin"><img src="/static/logout.svg"></router-link>  

    </div>
  </div>

</div>
</template>

<script>
export default {
data() {
  return {
    showModal: false,
    languages: [
      { name: 'English', flag: 'path/to/english-flag.png' },
      { name: 'Spanish', flag: 'path/to/spanish-flag.png' },
      { name: 'French', flag: 'path/to/french-flag.png' },
      { name: 'German', flag: 'path/to/german-flag.png' },
      { name: 'Chinese', flag: 'path/to/chinese-flag.png' },
    ],
  };
},
  methods: {
    isActiveTab(tabName) {
      return this.$route.name === tabName;
    },
  },
};
</script>